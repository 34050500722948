import { useEffect } from "react";
import { getCHPYPapamByName } from "../utils/utils";
import i18n from "../i18n";

export default function useCommonHooks() {
  useEffect(() => {
    localStorage.setItem("campaign_id", getCHPYPapamByName("campaign_id"));

    // refer on server
    // const w_id = getCHPYPapamByName("w_id");
    // const m_id = getCHPYPapamByName("m_id");
    // const b_id = getCHPYPapamByName("b_id");
    // const c_id = getCHPYPapamByName("c_id");

    // const referer = w_id || m_id || b_id || c_id;

    // if (!referer) return;

    // document.cookie = encodeURIComponent("referer") + "=" + encodeURIComponent(btoa(referer)) + ";";
  }, []);

  useEffect(() => {
    const languages = [
      { title: "English", flag: "us", code: "en", country: "US" },
      { title: "Русский", flag: "ru", code: "ru", country: "RU" },
    ];
    const paramLang = getCHPYPapamByName("lang");

    if (paramLang && languages.length) {
      const currentLang = languages.find((el) => {
        return `${el.code}_${el.country}` === paramLang;
      });

      if (currentLang) {
        const code = currentLang.code;
        localStorage.setItem("lang", code);
        i18n.changeLanguage(code);
      }
    }
  }, []);
}
